
  import { defineComponent, ref, onMounted, computed, reactive } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import BankRatesFilter from '@/components/dropdown/BankRatesFilter.vue';

  export default defineComponent({
    name: 'admin-bank-rates-log-reports-listing',
    components: {
      BankRatesFilter,
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('bankRatesLogReportsListing'), [
          translate('bank'),
        ]);
      });
      await store.dispatch(Actions.UPDATE_BANK_RATES_FILTER, {
        dateFrom: null,
        dateTo: null,
        action: null,
        status: null,
      });
      await store.dispatch(Actions.GET_BANK_RATES_LOG_REPORTS);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('user'),
          key: 'user',
          sortable: true,
        },
        {
          name: translate('actionDescription'),
          key: 'value',
          sortable: true,
        },
        {
          name: translate('action'),
          key: 'action',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },
        {
          name: translate('timeStamp'),
          key: 'createdAt',
          sortable: true,
        },
      ]);
      const excelHeader = reactive({
        'Created Date': {
          field: 'createdAt',
          callback: (value) => {
            return new Date(value).toISOString().slice(0, 10);
          },
        },
        'Action ': 'action',
        'Description ': 'value',
        Status: 'status',
        'Performed By': 'user',
      });
      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_BANK_RATE_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_BANK_RATES_LOG_REPORTS);
        loading.value = false;
      };
      const onTypeChange = async (values) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_BANK_RATES_FILTER, values.value);
        await store.dispatch(Actions.UPDATE_BANK_RATE_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_BANK_RATES_LOG_REPORTS);
        loading.value = false;
      };
      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_BANK_RATES_FILTER, value);
        await store.dispatch(Actions.GET_BANK_RATES_LOG_REPORTS);
        loading.value = false;
      };
      await store.dispatch(Actions.EXPORT_RATES_LOGS);

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.bankRatesLogReportsList),
        tableHeader,
        translate,
        totalItems: computed(() => store.getters.bankRatesListCount),
        pageChanged,
        loading,
        can,
        excelData: computed(() => store.getters.exportedLogReportsList),
        excelHeader,
        dateNow: new Date().toISOString().slice(0, 10),
        onTypeChange,
        onReset,
      };
    },
  });
