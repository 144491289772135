import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "me-4" }
const _hoisted_5 = {
  type: "button",
  class: "btn btn-flex btn-light btn-active-primary fw-bolder",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-start",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_6 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_customers_export_modal"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_13 = {
  key: 1,
  class: "badge badge-light-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_BankRatesFilter = _resolveComponent("BankRatesFilter")!
  const _component_vue3_json_excel = _resolveComponent("vue3-json-excel")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'banks-rates'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen031.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.translate('filter')), 1)
              ]),
              _createVNode(_component_BankRatesFilter, {
                onFilterRatesSelected: _ctx.onTypeChange,
                onReset: _ctx.onReset
              }, null, 8, ["onFilterRatesSelected", "onReset"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_vue3_json_excel, {
                "json-data": _ctx.excelData,
                fields: _ctx.excelHeader,
                worksheet: "Logs WorkSheet",
                name: 
              'logs_' + 'logs_' + new Date().toISOString().slice(0, 10) + '.xls'
            
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr078.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate('exportLogsReports')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["json-data", "fields", "name"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            loading: _ctx.loading,
            "rows-per-page": 10,
            total: _ctx.totalItems,
            "enable-items-per-page-dropdown": false,
            onCurrentChange: _ctx.pageChanged
          }, {
            "cell-id": _withCtx(({ row: bank }) => [
              _createTextVNode(_toDisplayString(bank.id), 1)
            ]),
            "cell-user": _withCtx(({ row: bank }) => [
              _createTextVNode(_toDisplayString(bank.user), 1)
            ]),
            "cell-value": _withCtx(({ row: bank }) => [
              _createTextVNode(_toDisplayString(bank.value), 1)
            ]),
            "cell-action": _withCtx(({ row: bank }) => [
              _createTextVNode(_toDisplayString(bank.action), 1)
            ]),
            "cell-status": _withCtx(({ row: bank }) => [
              (bank.status == 'success')
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(bank.status), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(bank.status), 1))
            ]),
            "cell-createdAt": _withCtx(({ row: bank }) => [
              _createTextVNode(_toDisplayString(bank.createdAt), 1)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}